import React from 'react';

const FeatureComponent=({feature})=>(
    <div className="feature">
       <img src={feature.img} alt="Feature icon" />
       <div className="feature-title">{feature.title}</div>
       <div className="feature-description">{feature.details}</div>
    </div>
);

export default FeatureComponent;