import React from 'react';
import FeatureComponent from '../components/common_view_components/FeatureComponent';

const WhyPerPerson = ({content}) =>(
     <section className="main-section" id="why-share">
      <div className="container flexbox-space-between align-top">
        <div className="section-copy">
          {/* <a href={content.play_button_link} target="_blank" rel="noopener noreferrer" className="video-how-it-works">
            <img src={content.play_button_icon} alt="Play icon" />
            {content.play_button_text}
          </a> */}
          <div className="section-heading">{content.heading}</div>
          {
              content.paragraphs.map((paragraph, index)=>(
                  <p className="section-paragraph" key={index}>{paragraph.text}</p>
              ))
          }
        </div>

        <div className="section-feature-list flexbox-space-between">
          {
              content.features.map((feature, index)=>(
                  <FeatureComponent feature={feature} key={index} />
              ))
          }
        </div>
      </div>
    </section>
);

export default WhyPerPerson;