import React from 'react';
import ProductsList from '../components/common_view_components/ProductsList';
import { StaticQuery, graphql } from "gatsby";
import _ from 'lodash';

const query2 = graphql`
   query{
     markdownRemark(frontmatter: { path: { eq: "/product" } }) {
       frontmatter{
          most_popular_product{
            popular_product_link_name
            section_heading
            subheader
            text1
            text2
            price_label
            group_label            
          }

       }
     }
     allMarkdownRemark(filter:{frontmatter:{path:{eq:"/product/"}}}) {
        edges {
          node {    
            frontmatter {
              product{
                product_link_name
                product_price
                number_of_owners
                product_name
                currency
                price_per_person_label
                product_images 
                description_intro                
                description 
                tech_details 
                type_of_group
              }
            }
          }
        }
     }
   }
`


const getProductsListFromQuery=(nodes_list)=>{
    let products = nodes_list.map((list_node)=>{
        return list_node.node.frontmatter.product
    });

    return products
}

const ProductOffers=()=>(
    <StaticQuery
      query={query2}
      render={(data)=>{
         const {allMarkdownRemark, markdownRemark} = data;
         const section_heading = markdownRemark.frontmatter.most_popular_product.section_heading;
         const most_popular_product = markdownRemark.frontmatter.most_popular_product;         
         const products = getProductsListFromQuery(allMarkdownRemark.edges);

         const popular_product_index = _.findIndex(products, { 'product_link_name': most_popular_product.popular_product_link_name});        
         most_popular_product.product = products[popular_product_index];
         products.splice(popular_product_index, 1);     

         return(
            <ProductsList
                products_list={products}
                section_heading={section_heading}
                most_popular_product={most_popular_product}
            />
         )
      }}
    />
);

export default ProductOffers;