import React from 'react';
import {divide} from '../utils';

const ProductCard = ({product})=>(
    <a href={'/product/' + product.product_link_name} className="product-item simple">
        <div className="product-data">
            {product.currency} {product.product_price} / {product.number_of_owners} &nbsp;
            <img src="/images/icon-user.svg" alt="User icon"/>
        </div>
        <div className="product-image-name">
            <div className="product-image-container">
                <img src={product.product_images[0]} className="product-image"/>
            </div>
            <h1>{product.product_name}</h1>
        </div>
        <div className="product-details-link flexbox-space-between">
          <span className="product-price-per-person">
            <strong>{product.currency} {divide(product.product_price, product.number_of_owners)}</strong>
            <small>{product.price_per_person_label}</small>
          </span>
            <img src="/images/icon-arrow-right.svg" alt="Product details"/>
        </div>
    </a>
)

export default ProductCard;