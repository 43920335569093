import React from 'react'
import {graphql } from 'gatsby'
import Layout from '../components/Layout'
import HomeBanner from '../components/HomeBanner'
import WhySharing from '../components/WhySharing'
import ProductOffers from '../components/ProductOffers'

export default class IndexPage extends React.Component {
  render() {

    const { data } = this.props;
    const {home_banner, why_sharing} = data.allMarkdownRemark.edges[0].node.frontmatter;

    return (
      <Layout>
        <HomeBanner content={home_banner} />

        <WhySharing content={why_sharing} />

        <ProductOffers />

      </Layout>
    )
  }
}


export const pageQuery = graphql`
  query IndexQuery {
    allMarkdownRemark(      
      filter: { frontmatter: { path: { eq: "/" } }}
    ) {
      edges {
        node {           
          frontmatter {
            home_banner {
              title              
              description              
              button_text              
            }
            why_sharing{
                play_button_link
                play_button_icon
                play_button_text 
                heading
                paragraphs{
                  text
                }
                features{
                  img
                  title
                  details
                }
            }             
          }           
        }
      }
    }   
  } 
`