import React from 'react';

const HomeBanner=({content})=>(
    <section className="hero-banner">
      <div className="container flexbox-space-between">
        <div className="hero-copy">
          <h1 className="hero-title" style={{ color: "white" }} >{content.title}</h1>
          <span className="hero-description">{content.description}</span>

          <a href="/#why-share" className="cta">{content.button_text}</a>
        </div>
      </div>
    </section>
);

export default HomeBanner;