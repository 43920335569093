import React from 'react';
import {divide} from '../utils';

const ProductCartLink=({content, product, addItem})=>(
    <a href="#" className="cta grouped" onClick={()=>{addItem(product)}}>
        <span className="price-per-person">
          <h2>{product.currency} {divide(product.product_price, product.number_of_owners)}</h2>
          <small>{product.price_per_person_label}</small>
        </span>
        <span className="button-label">
          <img src="/images/icon-cart.svg" alt="Cart icon" />
          <strong>{content.text1} {parseInt(product.number_of_owners) - 1} {content.text2}</strong>
        </span>
    </a>
);

export default ProductCartLink;