import _ from 'lodash';

const divide=(a, b)=>{
    return _.floor(_.divide(a, b),1)
}

const floor=(a)=>{
    return _.floor(a, 1);
}

export {divide, floor}
