import React from 'react';
import ProductCard from './ProductCard';
import ProductCartLink from './ProductCartLink';
import {navigate} from 'gatsby'

const ProductsList=({products_list, section_heading, most_popular_product={}})=> {
  const popular_product = ('product' in most_popular_product)? most_popular_product.product : {product_images:[]};

  const MostPopularProductSection=()=>{
      const goToProductPage=()=>{
        navigate('/product/'+popular_product.product_link_name);
      }      

      return(

        <div className="product-item promoted flexbox-space-between">
          <div className="product-image-container" onClick={goToProductPage}>
            <img src={popular_product.product_images[0]} alt="Product image" className="product-image"/>
          </div>
          <div className="product-details" onClick={goToProductPage}>
            <div className="product-badge">{most_popular_product.subheader}</div>
            <h1>{popular_product.product_name}</h1>
            <div className="product-price-grouping flexbox-space-between">
              <div className="product-price">
                <h2>{popular_product.currency} {popular_product.product_price}</h2>
                <small>{most_popular_product.price_label}</small>
              </div>
              <div className="product-grouping">
                <h2 className="capitalize-header">{popular_product.number_of_owners} {most_popular_product.text2}</h2>
                <small>{most_popular_product.group_label}</small>
              </div>
            </div>
            <ProductCartLink content={most_popular_product} product={popular_product} addItem={goToProductPage}/>
          </div>
        </div>
      )
  }

  return(
    <section className="main-section top-border gray" id="shop">
      <div className="container">
        <h1 className="section-heading">{section_heading}</h1>
        <div className="product-listing flexbox-space-between flex-wrap no-alignment">
          {
                most_popular_product && <MostPopularProductSection />
          }

          {
            products_list.map((product, index)=>(
                <ProductCard key={index} product={product}/>
            ))
          }
        </div>
      </div>
    </section>
  )
};

export default ProductsList;

